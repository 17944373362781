import axios, { AxiosResponse } from 'axios';
import IGetGroupsResponse from '../models/IGetGroupsResponse';
import IExternalGroup from '../models/IExternalGroup';
import IGroupMember from '../models/IGroupMember';
import IManagedGroup from '../models/IManagedGroup';

export default class GroupsApi {
    public static GetGroups(baseUrl: string, bookId: string): Promise<AxiosResponse<IGetGroupsResponse>> {
        return axios.get(`${baseUrl}/_layouts/imanuel/ws/GetGroups.ashx`, {
            params: {
                bid: bookId
            }
        });
    }

    public static GetGroupMembers(baseUrl: string, bookId: string, externalGroupId?: string, managedGroupId?: string): Promise<AxiosResponse<IGroupMember[]>> {
        return axios.get(`${baseUrl}/_layouts/imanuel/ws/GetGroupMembers.ashx`, {
            params: {
                bid: bookId,
                gid: externalGroupId,
                cid: managedGroupId
            }
        });
    }
    
    public static GetAllMembers(baseUrl: string, bookId: string, name: string): Promise<AxiosResponse<IGroupMember[]>> {
        return axios.get(`${baseUrl}/_layouts/imanuel/ws/GetExternalMembers.ashx`, {
            params: {
                bookId: bookId,
                name,
            }
        });
    }

    public static SaveExternalGroup(baseUrl: string, bookId: string, group: IExternalGroup, members: IGroupMember[]): Promise<AxiosResponse<IExternalGroup>> {
        return axios.post(`${baseUrl}/_layouts/imanuel/ws/SaveExternalGroup.ashx`, {
            bookId: bookId,
            groupId: group.Id,
            members,
            enabled: group.IsManaged || false,
            title: group.Name,
        },
            {}
        );
    }

    public static SaveManagedGroup(baseUrl: string, bookId: string, group: IManagedGroup, members: IGroupMember[]): Promise<AxiosResponse<IManagedGroup>> {
        return axios.post(`${baseUrl}/_layouts/imanuel/ws/SaveManagedGroup.ashx`, {
            bookId: bookId,
            groupId: group.Id,
            members,
            enabled: true,
            title: group.Name || 'Nouvelle classe',
        },
            {}
        );
    }

    public static DeleteManagedGroup(baseUrl: string, bookId: string, group: IManagedGroup): Promise<AxiosResponse<IManagedGroup>> {
        return axios.post(`${baseUrl}/_layouts/imanuel/ws/SaveManagedGroup.ashx`, {
            bookId: bookId,
            groupId: group.Id,
            enabled: false,
        },
            {}
        );
    }

    public static RemoveMyselfFromManagedGroup(baseUrl: string, bookId: string, group: IManagedGroup): Promise<AxiosResponse<IManagedGroup>> {
        return axios.post(`${baseUrl}/_layouts/imanuel/ws/RemoveFromManagedGroup.ashx`, {
            bookId: bookId,
            groupId: group.Id,
            enabled: true,
        },
            {}
        );
    }
}