import React, { useEffect, useState, useMemo } from 'react';
import { Checkbox, FormControl, FormLabel, FormControlLabel, TextField, CircularProgress, Switch } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import BookResourcesApi from '../../api/BookResourcesApi';
import { parse } from '../../helpers/QueryStringHelper';
import IBookResources from '../../models/IBookResources';
import IBookResource from '../../models/IBookResource';
import ISelectableSummaryItem from '../../models/ISelectableSummaryItem';
import IBookSummaryItem from '../../models/IBookSummaryItem';
import IResourceType from '../../models/IResourceType';
import ISharePointContext from '../../models/ISharePointContext';
import BookResourcesList from './components/bookResourcesList/BookResourcesList';

declare var _spPageContextInfo: ISharePointContext;

function BookResourcesLibrary() {
  const queryParams = parse(document.location.search);
  const bookId: string = queryParams["bid"];
  const [bookResources, setBookResources] = useState<IBookResources>();
  const [filteredResources, setFilteredResources] = useState<IBookResource[]>([]);
  const [filteredResourceTypes, setFilteredResourceTypes] = useState<IResourceType[] | null>(null);
  const [summaryItems, setSummaryItems] = useState<ISelectableSummaryItem[]>([]);
  const [selectedResourceTypes, setSelectedResourceTypes] = useState<IResourceType[]>([]);
  const [selectedSummaryItems, setSelectedSummaryItems] = useState<ISelectableSummaryItem[]>([]);
  const [resourcesLoaded, setResourcesLoaded] = useState<boolean>(false);
  const [onlyCorrections, setOnlyCorrections] = useState<boolean>(false);
  const [hasCorrections, setHasCorrections] = useState<boolean>(false);

  const resourceTypes: IResourceType[] = [
    { Id: 'video', Title: 'Vidéos' },
    { Id: 'html', Title: 'Documents' },
    { Id: 'pdf', Title: 'Fichiers PDF' },
    { Id: 'ppt', Title: 'Fichiers PowerPoint' },
    { Id: 'doc', Title: 'Fichiers Word' },
    { Id: 'xls', Title: 'Fichiers Excel' },
    { Id: 'zip', Title: 'Fichiers ZIP' },
    { Id: 'file', Title: 'Autres fichiers' },
    { Id: 'audio', Title: 'Audio' },
    { Id: 'link', Title: 'Liens' },
  ];

  useEffect(() => {
    const getChildSummaryIds = (summaryItem: IBookSummaryItem): string[] => {
      let ids: string[] = [];
      summaryItem.Items?.forEach((item) => {
        if (item.Id) ids.push(item.Id);
        const childIds = getChildSummaryIds(item);
        if (childIds.length > 0) Array.prototype.push.apply(ids, childIds);
      });
      return ids;
    };

    if (!bookResources) {
      const url = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : document.location.origin;
      BookResourcesApi.GetResources(url, bookId).then((res) => {
        let sumItems: ISelectableSummaryItem[] = [];
        let sumItemCounter = 0;
        if (res.data.SummaryItems) {
          // Keep only the top two levels, first is not selectionable
          res.data.SummaryItems.forEach((si) => {
            if (si.Items && si.Items.length > 0) {
              const header = `${si.Title}${si.SubTitle1 ? ` - ${si.SubTitle1}` : ''}`;
              sumItems.push({
                Id: si.Id,
                Title: header,
                Selectable: false,
                ChildIds: [],
                Order: sumItemCounter++
              });

              si.Items.forEach((subSi) => {
                sumItems.push({
                  Id: subSi.Id,
                  Title: `${subSi.Title}${subSi.SubTitle1 ? ` - ${subSi.SubTitle1}` : ''}`,
                  Selectable: true,
                  ChildIds: getChildSummaryIds(subSi),
                  Header: header,
                  Order: sumItemCounter++
                });
              });
            }
          });
        }
        if (res.data && res.data.Resources) {
          if (res.data.Resources.filter(r => r.SummaryId === `${bookId}_ExternalResources`).length > 0) {
            sumItems.push({
              Id: `ExternalResources`,
              Title: "---------------------------",
              Selectable: false,
              ChildIds: [],
              Order: sumItemCounter++
            });
            sumItems.push({
              Id: `${bookId}_ExternalResources`,
              Title: "Ressources complémentaires",
              Selectable: true,
              ChildIds: [],
              Header: "Ressources complémentaires du manuel",
              Order: sumItemCounter++
            });
          }
          const fResCorr = res.data.Resources.filter(r => r.Correction);
          if (fResCorr.length > 0) setHasCorrections(true);
        }
        setBookResources(res.data);
        setSummaryItems(sumItems);
        setSelectedSummaryItems([]);
        setSelectedResourceTypes([]);
        setResourcesLoaded(true);
      });
    }
  }, [bookResources]);

  useEffect(() => {
    if (bookResources && selectedSummaryItems) {
      let sumIds: string[] = [];
      // get summary items ids to be able to retrieve corresponding resources
      selectedSummaryItems?.forEach((si) => {
        if (si.Id) sumIds.push(si.Id);
        Array.prototype.push.apply(sumIds, si.ChildIds);
      });
      // filter to show only visible (i.e. not hidden) and related resources
      const filtered = bookResources?.Resources ? bookResources.Resources.filter((br) =>
        (!br.Hidden) &&
        (br.Correction === onlyCorrections) &&
        (sumIds && (sumIds.length === 0 || sumIds.indexOf(br.SummaryId) >= 0))) : [];
      // prepare filters on resource types (with numbers)
      let fResourceTypes: IResourceType[] = [];
      resourceTypes.forEach((rt) => {
        const rTypeCount = (bookResources?.Resources ?
          bookResources.Resources.filter((br) =>
            (!br.Hidden) &&
            (br.Type === rt.Id) &&
            (br.Correction === onlyCorrections) &&
            (sumIds && (sumIds.length === 0 || sumIds.indexOf(br.SummaryId) >= 0)))
          : []).length;

        if (rTypeCount > 0) {
          fResourceTypes.push({
            Id: rt.Id,
            Title: `${rt.Title} (${rTypeCount})`
          })
        }
      });
      // Get only referenced types
      const filteredWithResTypes = filtered.filter((br) => (selectedResourceTypes && (selectedResourceTypes.length === 0 || selectedResourceTypes.filter((rt) => rt.Id === br.Type).length > 0)));
      
      setFilteredResourceTypes(fResourceTypes);
      setFilteredResources(filteredWithResTypes);
    }
    else {
      setFilteredResourceTypes([]);
      setFilteredResources([]);
    }
  }, [bookResources, selectedResourceTypes, onlyCorrections, selectedSummaryItems]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (<div className="book-resources-library" style={{ width: '100%', marginTop: 20 }}>
    { !resourcesLoaded && (
      <CircularProgress />
    )}
    { resourcesLoaded && (
      <>
        <FormControl fullWidth>
          <FormLabel style={{ marginTop: 15, marginBottom: 5 }}>Chapitre</FormLabel>
          <Autocomplete
            key="chapterFilter"
            multiple
            limitTags={3}
            disableCloseOnSelect={true}
            renderInput={(params) => <TextField {...params} label="Filtrez par chapitres" variant="outlined" />}
            noOptionsText="Aucun chapitre"
            renderOption={(option, { selected }) => (
              <React.Fragment>
                { option.Selectable && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginLeft: 15, marginRight: 8 }}
                    checked={selected}
                  />
                )}
                <span style={{ fontWeight: option.Selectable ? 700 : 300 }} dangerouslySetInnerHTML={{ __html: option.Title }}></span>
              </React.Fragment>
            )}
            getOptionDisabled={(option) => !option.Selectable}
            getOptionLabel={(option) => {
              const e = document.createElement("div");
              e.innerHTML = option.Title;
              const title = e.innerText;
              return title.length > 30 ? title.substr(0, 30).trim() + "..." : title
            }
            }
            onChange={(ev, newValue) => {
              setSelectedResourceTypes([]);
              setSelectedSummaryItems(newValue);
            }}
            options={summaryItems ?? []} />
          <FormLabel style={{ marginTop: 15, marginBottom: 5 }}>Type</FormLabel>
          <Autocomplete
            key="fileTypeFilter"
            renderInput={(params) => <TextField {...params} label="Filtrez par type" variant="outlined" />}
            noOptionsText="Aucun type"
            multiple
            filterSelectedOptions
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.Title}
              </React.Fragment>
            )}
            getOptionLabel={(option) => option.Title}
            onChange={(ev, newValue) => {
              setSelectedResourceTypes(newValue);
            }}
            getOptionSelected={(option, value) => option.Title === value.Title}
            options={filteredResourceTypes ?? []}
            value={selectedResourceTypes} />
          {hasCorrections && (
            <FormControlLabel
              control={<Switch checked={onlyCorrections} onChange={(ev, checked) => setOnlyCorrections(checked)} name="corrections" />}
              label="Afficher les fichiers enseignant"
            />
          )}
        </FormControl>
        <BookResourcesList items={filteredResources} summaryItems={selectedSummaryItems && selectedSummaryItems.length > 0 ? selectedSummaryItems : (summaryItems ?? [])} />
      </>
    )}
  </div>);
}

export default BookResourcesLibrary;
