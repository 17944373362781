import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IBookResource from '../../../../models/IBookResource';
import ISelectableSummaryItem from '../../../../models/ISelectableSummaryItem';
import FileIcon from './images/res_file.png';
import AudioIcon from './images/res_audio.png';
import VideoIcon from './images/res_video.png';
import LinkIcon from './images/res_link.png';
import { Grid, Slide, Dialog, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);

interface BookResourcesListProps {
    items: IBookResource[] | undefined;
    summaryItems: ISelectableSummaryItem[];
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const BookResourcesList: React.FC<BookResourcesListProps> = (props) => {
    const { items, summaryItems } = props;
    const classes = useStyles();
    const [selectedResource, setSelectedResource] = useState<IBookResource>();

    const handleClose = () => {
        setSelectedResource(undefined);
    };

    const displayExtensions = false;
    let previousHeader: string | undefined = undefined;

    return (<>
        { !items || items.length === 0 ?
            (<h2 style={{ fontSize: "18px", lineHeight: "18px", fontWeight: 700, marginTop: 15, marginBottom: 10 }}>Aucune ressource disponible</h2>) :
            (<Grid container spacing={2} style={{ padding: 10 }} direction={"column"}>
                {
                    summaryItems.sort((a, b) => a.Order < b.Order ? -1 : 1).map((sumItem, idx) => {
                        const curItems = items.filter(i => sumItem.Id === i.SummaryId || sumItem.ChildIds.indexOf(i.SummaryId) >= 0);
                        let hasNewHeader = false;
                        if (sumItem.Header && curItems.length > 0) {
                            hasNewHeader = previousHeader !== sumItem.Header;
                            previousHeader = sumItem.Header;
                        }
                        if (curItems.length > 0)
                            return (<div key={`${idx}__${sumItem.Id}`}>
                                <Grid item>
                                    { hasNewHeader && sumItem.Header && (<h3 style={{ fontSize: "16px", lineHeight: "18px", fontWeight: 300, marginTop: 10, marginBottom: 5 }} dangerouslySetInnerHTML={{ __html: sumItem.Header }}></h3>)}
                                    <h2 style={{ fontSize: "18px", lineHeight: "18px", fontWeight: 700, marginTop: 15, marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: sumItem.Title }}></h2>
                                </Grid>
                                { curItems.map((item, iIdx) =>
                                    <Grid key={`${iIdx}__${item.Id}`} item alignItems={"flex-start"} container direction="row">
                                        {(item.Type === "file" || item.Type === "doc" || item.Type === "xls" || item.Type === "ppt" || item.Type === "pdf" || item.Type === "zip") && (<img src={FileIcon} alt="Fichier" style={{ marginLeft: 30, marginRight: 10 }} />)}
                                        {item.Type === "html" && (<img src={FileIcon} alt="Document" style={{ marginLeft: 30, marginRight: 10 }} />)}
                                        {item.Type === "audio" && (<img src={AudioIcon} alt="Audio" style={{ marginLeft: 30, marginRight: 10 }} />)}
                                        {item.Type === "video" && (<img src={VideoIcon} alt="Vidéo" style={{ marginLeft: 30, marginRight: 10 }} />)}
                                        {item.Type === "link" && (<img src={LinkIcon} alt="Lien" style={{ marginLeft: 30, marginRight: 10 }} />)}
                                        {(item.Url.indexOf('documentviewer.aspx') > 0) ?
                                            (<a href="#" onClick={() => setSelectedResource(item)} style={{ lineHeight: "34px", fontSize: "16px" }}>{item.Title}{displayExtensions && item.Extension ? ` (${item.Extension})` : ''}</a>) :
                                            (<a href={item.Url} target="_blank" style={{ lineHeight: "34px", fontSize: "16px" }}>{item.Title}{displayExtensions && item.Extension ? ` (${item.Extension})` : ''}</a>)
                                        }
                                    </Grid>)
                                }
                            </div>);
                    })
                }
            </Grid>
            )}
        <Dialog fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={!!selectedResource} TransitionComponent={Transition}>
            <MuiDialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">{selectedResource?.Title}</Typography>
                <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent>
                <iframe src={selectedResource ? selectedResource.Url : "#"} style={{ width: '100%', height: 'calc(100% - 20px)' }} />
            </MuiDialogContent>
        </Dialog>
    </>);
}

export default BookResourcesList;