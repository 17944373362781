import {
    Typography,
    Button,
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContentText,
    TextField,
    Tooltip
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import UndoIcon from '@material-ui/icons/Undo';
import ConfirmIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import IdoIcon from '@material-ui/icons/ErrorOutline';
import RemoveUserIcon from '@material-ui/icons/PersonAddDisabled';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IManagedGroup from '../../../models/IManagedGroup';
import IGroupMember from '../../../models/IGroupMember';
import GroupMembersList from './GroupMembersList';
import { useMemo, useState, Dispatch, SetStateAction } from 'react';

dayjs.locale('fr');

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '20px 15px',
    },
    existingTeacher: {
        display: 'block',
        marginTop: 5,
    },
    hiddenGroupMember: {
        color: '#C0C0C0',
        fontStyle: 'italic'
    },
    teacher: {
        fontWeight: 'bold',
        color: '#6F6F6F'
    },
    actions: {
        marginTop: 20
    },
    rightActionButton: {
        marginLeft: 20,
        marginBottom: 5,
    },
    leftActionButton: {
        marginRight: 20,
        marginBottom: 5,
    },
    noMembers: {
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 20
    }
}));

interface ManagedGroupTabPanelProps {
    children?: React.ReactNode;
    id: string;
    firstTime: boolean;
    bookId: string;
    value: IManagedGroup | undefined;
    groupMembers: IGroupMember[];
    setGroupMembers: Dispatch<SetStateAction<IGroupMember[]>>;
    groupMembersLoading: boolean;
    canSave: boolean;
    changeGroupName: (groupName: string) => void;
    save: () => void;
    cancel: () => void;
    removeMyself: () => void;
    goNext: () => void;
    remove: () => void;
}

const ManagedGroupTabPanel = (props: ManagedGroupTabPanelProps) => {
    const classes = useStyles();
    const {
        value,
        id,
        firstTime,
        bookId,
        groupMembers,
        groupMembersLoading,
        canSave,
        setGroupMembers,
        changeGroupName,
        save,
        goNext,
        cancel,
        remove,
        removeMyself,
        ...other
    } = props;
    const isCurrentTab = value && value.Id === id;
    const [openMembersListDialog, setOpenMembersListDialog] = useState<boolean>(false);
    const [selectedMembers, setSelectedMembers] = useState<IGroupMember[]>();
    //const [groupName, setGroupName] = useState<string>(value?.Name || 'Classe sans nom');
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
    const [openConfirmRemoveMyselfDialog, setOpenConfirmMyselfDialog] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const handleConfirmDeleteDialogClose = () => {
        setOpenConfirmDeleteDialog(false);
    };

    const handleMembersListDialogClose = () => {
        setOpenMembersListDialog(false);
        setSelectedMembers(undefined);
    };

    const handleRemove = () => {
        setOpenConfirmDeleteDialog(true);
    };

    const handleConfirmMyselfDialogClose = () => {
        setOpenConfirmMyselfDialog(false);
    };

    const handleMembersListDialogOpen = () => {
        setOpenMembersListDialog(true);
    };

    const handleRemoveMyself = () => {
        setOpenConfirmMyselfDialog(true);
    }

    const handleCancel = () => {
        cancel();
        setIsChanged(false);
    }

    const handleSave = () => {
        save();
        setIsChanged(false);
    }

    const handleRemoveGroupMember = (member: IGroupMember) => {
        // add // need to do distinct
        const idxMember = groupMembers.findIndex((m) => m.Id === member.Id);
        if (idxMember >= 0) {
            setGroupMembers([
                ...groupMembers.slice(0, idxMember),
                ...groupMembers.slice(idxMember + 1)
            ]);
            setIsChanged(true);
        }
    };

    const handleMembersListDialogConfirm = () => {
        if (selectedMembers && selectedMembers.length > 0) {
            // add // need to do distinct
            const currentMembersIds = groupMembers.map((m) => m.Id);
            const uniqueSelectedMembers = selectedMembers.filter((s) => currentMembersIds.indexOf(s.Id) < 0).map((m) => {
                m.Extra = true;
                return m;
            });
            const validNewGroupMembers = [...groupMembers, ...uniqueSelectedMembers];
            setGroupMembers(validNewGroupMembers);
            setIsChanged(true);
        }
        setSelectedMembers(undefined);
        setOpenMembersListDialog(false);
    };

    const handleGroupNameChange = (ev: any) => {
        changeGroupName(ev.target.value);
        //setGroupName(ev.target.value);
        setIsChanged(true);
    };

    const isUniqueTeacher = useMemo<boolean>(() => {
        return (!isChanged && groupMembers.filter((gm) => gm.RoleLabel === "professeur").length === 0);
    }, [groupMembers, isChanged]);

    return (
        <div
            role="tabpanel"
            hidden={!isCurrentTab}
            id={`scrollable-auto-tabpanel-${id}`}
            aria-labelledby={`scrollable-auto-tab-${id}`}
            style={{ marginTop: 0, border: '1px solid #D3D3D3' }}
            {...other}
        >
            {isCurrentTab && value && (
                <Box p={3}
                    className={classes.root}>
                    <div>
                        <Typography variant={"body2"}>Ceci est une classe que vous avez créée.</Typography>
                        <Typography style={{ marginTop: 20 }}>Modifier le nom de la classe:</Typography>
                        <TextField
                            value={value.Name}
                            fullWidth
                            size="small"
                            inputProps={{
                                maxLength: 250
                            }}
                            onClick={() => {
                                if (value.Name === 'Nouvelle classe' && value.IsNew) {
                                    //setGroupName('');
                                    changeGroupName('');
                                }
                            }}
                            disabled={groupMembersLoading}
                            onChange={handleGroupNameChange}
                        />

                        {groupMembersLoading && (
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <CircularProgress />
                                <Typography>Chargement des membres...</Typography>
                            </div>)}
                        {!groupMembersLoading && (<>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="stretch"
                                className={classes.actions}
                            >
                                <Button
                                    className={classes.rightActionButton}
                                    disabled={!canSave}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    onClick={handleMembersListDialogOpen}>Ajouter un élève</Button>
                            </Grid>
                            <TableContainer>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={150}>Nom</TableCell>
                                            <TableCell>Prénom</TableCell>
                                            <TableCell width={80}>Profil</TableCell>
                                            <TableCell width={150} align="center">Date 1ere connexion</TableCell>
                                            <TableCell width={150} align="center">Date fin de licence</TableCell>
                                            <TableCell width={50}></TableCell>
                                            <TableCell align="center" width={100}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupMembers.filter(gm => gm.RoleLabel == "élève").map((member) => (
                                            <TableRow key={member.Id}>
                                                <TableCell component="th" scope="row">{member.LastName}</TableCell>
                                                <TableCell>{member.FirstName}</TableCell>
                                                <TableCell style={{ textTransform: 'capitalize' }}>{member.RoleLabel}</TableCell>
                                                <TableCell align="center">{member.CreationDate ? dayjs(member.CreationDate).format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell align="center">
                                                    {member.ExpirationDate ? dayjs(member.ExpirationDate).format('DD/MM/YYYY') : '-'}
                                                </TableCell>
                                                <TableCell>{member.Login && member.Login.indexOf('.IDO.') > 0 && (<IdoIcon titleAccess="IDO" />)}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<RemoveUserIcon />}
                                                        onClick={() => handleRemoveGroupMember(member)}>Retirer</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {groupMembers.filter(gm => gm.RoleLabel == "professeur").map((member) => (
                                            <TableRow key={member.Id}>
                                                <TableCell component="th" scope="row" className={classes.teacher}>{member.LastName}</TableCell>
                                                <TableCell className={classes.teacher}>{member.FirstName}</TableCell>
                                                <TableCell className={classes.teacher} style={{ textTransform: 'capitalize' }}>{member.RoleLabel}</TableCell>
                                                <TableCell className={classes.teacher} align="center">-</TableCell>
                                                <TableCell className={classes.teacher} align="center">{member.ExpirationDate ? dayjs(member.ExpirationDate).format('DD/MM/YYYY') : '-'}</TableCell>
                                                <TableCell align="center">
                                                    {member.Login && member.Login.indexOf('.IDO.') > 0 && (<IdoIcon titleAccess="IDO" />)}
                                                </TableCell>
                                                <TableCell className={classes.teacher} align="center">
                                                    {member.Extra && (
                                                        <Tooltip title="Vous ne pouvez pas retirer un enseignant" aria-label="Vous ne pouvez pas retirer un enseignant">
                                                            <div>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    disabled={true}
                                                                    startIcon={<RemoveUserIcon />}>Retirer</Button>
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid
                                container
                                justify="flex-end"
                                alignItems="stretch"
                            >
                                <Grid
                                    className={classes.actions}
                                    item
                                    xs={6}>
                                    {!value?.IsNew && (
                                        <Button
                                            className={classes.leftActionButton}
                                            disabled={!canSave}
                                            startIcon={<DeleteIcon />}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleRemove}>Supprimer la classe</Button>)}
                                    {!isUniqueTeacher && !value?.IsNew && (
                                        <Button
                                            className={classes.leftActionButton}
                                            disabled={!canSave || isChanged}
                                            startIcon={<RemoveUserIcon />}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleRemoveMyself}>Se retirer de la classe</Button>)}
                                </Grid>
                                <Grid
                                    className={classes.actions}
                                    item
                                    style={{ textAlign: 'right' }}
                                    xs={6}>
                                    {!value?.IsNew && (<Button
                                        className={classes.rightActionButton}
                                        disabled={!isChanged}
                                        color="secondary"
                                        startIcon={<UndoIcon />}
                                        onClick={handleCancel}>Annuler</Button>)}
                                    <Button
                                        className={classes.rightActionButton}
                                        disabled={!canSave || !isChanged || !value.Name}
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleSave}>Sauvegarder</Button>
                                </Grid>
                            </Grid>
                        </>)}
                    </div>
                    <Dialog
                        key="listMembersDialog"
                        open={openMembersListDialog}
                        onClose={handleMembersListDialogClose}
                        aria-labelledby="alert-dialog-title"
                        fullWidth
                        maxWidth={"lg"}
                    >
                        <DialogTitle id="alert-dialog-title">{"Sélection des élèves"}</DialogTitle>
                        <DialogContent>
                            <div style={{ marginBottom: 15, fontSize: '14px' }}><b>Seuls les élèves qui se sont connectés au moins une fois à leur manuel apparaissent dans la liste ci-dessous.</b></div>
                            {openMembersListDialog && (
                                <GroupMembersList
                                    bookId={bookId}
                                    setSelectedMembers={setSelectedMembers}
                                    disabledMemberIds={groupMembers.map((m) => m.Id)}
                                />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.rightActionButton}
                                onClick={handleMembersListDialogClose}
                                color="primary">Annuler</Button>
                            <Button
                                className={classes.rightActionButton}
                                color="secondary"
                                variant="contained"
                                autoFocus
                                startIcon={<ConfirmIcon />}
                                onClick={handleMembersListDialogConfirm}>Confirmer</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        key="removeGroupMemberDialog"
                        open={openConfirmDeleteDialog}
                        onClose={handleConfirmDeleteDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirmation de la suppression de la classe"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si vous confirmez, la classe sera supprimée ainsi que les accès élèves et enseignants associés.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleConfirmDeleteDialogClose}
                                className={classes.rightActionButton}
                                color="primary">Annuler</Button>
                            <Button
                                onClick={() => {
                                    handleConfirmDeleteDialogClose();
                                    remove();
                                }}
                                className={classes.rightActionButton}
                                color="secondary"
                                variant="contained"
                                startIcon={<ConfirmIcon />}
                                autoFocus>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        key="removeMyselfDialog"
                        open={openConfirmRemoveMyselfDialog}
                        onClose={handleConfirmDeleteDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirmation de votre retrait de la classe"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Vous êtes sur le point de vous retirer de la gestion de cette classe. Celle-ci disparaitra de vos classes et ne sera visible que par les autres enseignants actuellement désignés:
                                {groupMembers.filter((gm) => gm.RoleLabel === "professeur").map((gm) => (<span className={classes.existingTeacher} key={gm.Id}> - {gm.FirstName} {gm.LastName}</span>)) }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleConfirmMyselfDialogClose}
                                className={classes.rightActionButton}
                                color="primary">Annuler</Button>
                            <Button
                                onClick={() => {
                                    handleConfirmMyselfDialogClose();
                                    removeMyself();
                                }}
                                className={classes.rightActionButton}
                                color="secondary"
                                variant="contained"
                                startIcon={<ConfirmIcon />}
                                autoFocus>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
        </div>
    );
}

export default ManagedGroupTabPanel;
