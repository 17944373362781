import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import IGroupMember from '../../../models/IGroupMember';
import ISharePointContext from '../../../models/ISharePointContext';
import GroupsApi from '../../../api/GroupsApi';
import { DataGrid, frFR, LocalizationV4 } from '@material-ui/data-grid';
import { CircularProgress } from '@material-ui/core';

interface GroupMembersListsProps {
    bookId: string;
    disabledMemberIds: string[];
    setSelectedMembers: Dispatch<SetStateAction<IGroupMember[] | undefined>>;
};

declare var _spPageContextInfo: ISharePointContext;

const columns = [
    { field: 'Id', headerName: 'ID', width: 20, hide: true },
    {
        field: 'fullName',
        headerName: `Utilisateurs de l'établissement`,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        flex: 2,
        valueGetter: (params: any) =>
            `${params.row.LastName || ''} ${params.row.FirstName || ''}${params.row.Login && params.row.Login.indexOf('.IDO.') > 0 ? ' - IDO' : ''}`,
    },
    {
        field: 'RoleLabel',
        headerName: 'Profil',
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        width: 120,
        valueGetter: (params: any) =>
            params.row.RoleLabel && params.row.RoleLabel.length > 1 ?
                `${params.row.RoleLabel[0].toUpperCase()}${params.row.RoleLabel.substr(1)}` :
                params.row.RoleLabel
    },
    {
        field: 'Login',
        headerName: 'Login',
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        width: 200
    },
];

const dataGridLocalization = (frFR as LocalizationV4).props.MuiDataGrid.localeText;

const GroupMembersLists = (props: GroupMembersListsProps) => {
    const { bookId, disabledMemberIds, setSelectedMembers } = props;
    const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
    const [members, setMembers] = useState<IGroupMember[]>();
    const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>();

    const getMembersByName = (name: string) => {
        const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;
        setLoadingMembers(true);
        GroupsApi.GetAllMembers(baseApiUrl, bookId, name).then((results) => {
            setMembers(results.data);
        }).finally(() => {
            setLoadingMembers(false);
        });
    }

    useEffect(() => {
        if (!members) {
            getMembersByName('');
        }
    }, [members]);

    useEffect(() => {
        if (members && selectedMemberIds && selectedMemberIds.length > 0) {
            const selected = members.filter((m) => selectedMemberIds.indexOf(m.Id) >= 0);
            setSelectedMembers(selected);
        } else {
            setSelectedMembers(undefined);
        }
    }, [members, selectedMemberIds]);

    return (<div style={{ height: 400, width: '100%' }}>
        {loadingMembers && (<CircularProgress />)}
        {!loadingMembers && members && (
                <DataGrid
                    getRowId={(row) => row.Id}
                    rows={members}
                    columns={columns}
                    pageSize={10}
                    localeText={dataGridLocalization}
                    checkboxSelection
                    isRowSelectable={(row) => disabledMemberIds.indexOf(row.row.Id) < 0}
                    onSelectionModelChange={(changes) => {
                        setSelectedMemberIds(changes.selectionModel.map((v) => v.toString()));
                    }}
                />
        )}
    </div>);
}

export default GroupMembersLists;