import axios, { AxiosResponse } from 'axios';
import IBookResources from '../models/IBookResources';

export default class BookResourcesApi {
    public static GetResources(baseUrl: string, bookId: string): Promise<AxiosResponse<IBookResources>> {
        return axios.get(`${baseUrl}/_layouts/imanuel/ws/GetResources.ashx`, { 
            params: {
                bid : bookId
            }
        });
    }
} 