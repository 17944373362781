import { useEffect, useState } from 'react';
import { AppBar, Tab, Tabs, Button, Snackbar, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import GroupsApi from '../../api/GroupsApi';
import IExternalGroup from '../../models/IExternalGroup';
import IManagedGroup from '../../models/IManagedGroup';
import IGroupMember from '../../models/IGroupMember';
import ISharePointContext from '../../models/ISharePointContext';
import ExternalGroupTabPanel from './components/ExternalGroupTabPanel';
import ManagedGroupTabPanel from './components/ManagedGroupTabPanel';
import { Alert } from '@material-ui/lab';
import { v4 as uuid_v4 } from "uuid";

declare var _spPageContextInfo: ISharePointContext;

const urlParams = new URLSearchParams(window.document.location.search);
const currentBookId = urlParams.get('bid') || '';
const defaultClassId = urlParams.get('classid') || '';
const firstTime = urlParams.get('firsttime') === '1';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: 30,
        backgroundColor: theme.palette.background.paper,
    },
    tab: {
        minWidth: 150
    },
    tabNew: {
        minWidth: 150,
        color: theme.palette.primary.main
    },
    tabSelected: {
        color: 'blue'
    },
    hiddenGroupMember: {
        color: '#C0C0C0',
        fontStyle: 'italic'
    }
}));


const GroupsDashboard = () => {
    const classes = useStyles();
    const [externalGroups, setExternalGroups] = useState<IExternalGroup[]>();
    const [managedGroups, setManagedGroups] = useState<IManagedGroup[]>();
    const [selectedExternalGroup, setSelectedExternalGroup] = useState<IExternalGroup>();
    const [selectedManagedGroup, setSelectedManagedGroup] = useState<IManagedGroup>();
    const [tabSelectedIndex, setTabSelectedIndex] = useState<number>();
    const [selectedExternalGroupIndex, setSelectedExternalGroupIndex] = useState<number>();
    const [selectedManagedGroupIndex, setSelectedManagedGroupIndex] = useState<number>();
    const [selectedGroupMembersLoading, setSelectedGroupMembersLoading] = useState<boolean>(false);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState<IGroupMember[]>([]);
    const [savingGroup, setSavingGroup] = useState<boolean>(false);
    const [loadingGroups, setLoadingGroups] = useState<boolean>(true);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        if (!externalGroups) {
            const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

            // Get groups
            GroupsApi.GetGroups(baseApiUrl, currentBookId)
                .then((g) => {
                    const extGroups = g.data.External || [];
                    const manGroups = g.data.Managed || [];
                    setLoadingGroups(false);
                    setExternalGroups(extGroups);
                    setManagedGroups(manGroups);

                    const defaultManagedIdx = defaultClassId ? manGroups.findIndex((g) => g.Id === defaultClassId) : -1;
                    if (defaultManagedIdx >= 0) {
                        setSelectedManagedGroupIndex(defaultManagedIdx);
                        setTabSelectedIndex(extGroups.length + defaultManagedIdx);
                    }
                    else {
                        // Select default
                        if (extGroups.length > 0) {
                            setSelectedExternalGroupIndex(0);
                            setTabSelectedIndex(0);
                        } else if (manGroups.length > 0) {
                            setSelectedManagedGroupIndex(0);
                            setTabSelectedIndex(0);
                        }
                    }
                });
        }
    }, [externalGroups]);

    const handleGroupIsManaged = (groupId: string, isManaged: boolean) => {
        console.log(`Group ${groupId} is managed ? ${isManaged}`);

        if (externalGroups && selectedExternalGroupIndex !== undefined && selectedExternalGroupIndex >= 0 && selectedExternalGroup) {
            selectedExternalGroup.IsManaged = isManaged;
            setExternalGroups([...externalGroups.slice(0, selectedExternalGroupIndex),
                selectedExternalGroup,
            ...externalGroups.slice(selectedExternalGroupIndex + 1)
            ]);
        }
    };

    const handleGroupMemberIsIgnored = (groupId: string, userId: string, ignored: boolean) => {
        console.log(`Group member ${userId} in group ${groupId} is ignored ? ${ignored}`);

        if (externalGroups &&
            selectedExternalGroupIndex !== undefined &&
            selectedExternalGroupIndex >= 0 &&
            selectedExternalGroup &&
            selectedGroupMembers) {
            const groupMemberIndex = selectedGroupMembers.findIndex((m) => m.Id === userId);
            if (groupMemberIndex >= 0) {
                const updatedMember = selectedGroupMembers[groupMemberIndex];
                updatedMember.Ignored = ignored;
                setSelectedGroupMembers([...selectedGroupMembers.slice(0, groupMemberIndex),
                    updatedMember,
                ...selectedGroupMembers.slice(groupMemberIndex + 1)]);
            }
        }
    };

    const handleGroupChange = (newValue: number) => {
        if (externalGroups && managedGroups && newValue >= 0)
            if (newValue < externalGroups.length) {
                setSelectedExternalGroupIndex(newValue);
                setSelectedManagedGroupIndex(undefined);
                setTabSelectedIndex(newValue);
            }
            else {
                const newIndex = newValue - externalGroups.length;
                if (newIndex == managedGroups.length) {
                    // then click on new class
                    const newGroup = {
                        Id: uuid_v4(),
                        ExtId: '',
                        Name: 'Nouvelle classe',
                        SchoolId: '',
                        IsNew: true
                    };

                    setManagedGroups([
                        ...managedGroups,
                        newGroup,
                    ]);
                    setSelectedGroupMembers([]);
                    setSelectedManagedGroup(newGroup);
                }
                setSelectedManagedGroupIndex(newIndex);
                setSelectedExternalGroupIndex(undefined);
                setTabSelectedIndex(newValue);
            }
        else {
            setSelectedExternalGroupIndex(undefined);
            setSelectedManagedGroupIndex(undefined);
            setTabSelectedIndex(undefined);
        }
    };

    const handleExternalCancel = () => {
        if (selectedExternalGroup) {
            // Reload group members
            const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

            GroupsApi.GetGroupMembers(baseApiUrl, currentBookId, selectedExternalGroup.Id, undefined)
                .then((members) => {
                    setSelectedGroupMembers(members.data);
                    setSelectedGroupMembersLoading(false);
                });
        }
    };

    const handleManagedCancel = () => {
        if (selectedManagedGroup) {
            // Reload group members
            const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

            GroupsApi.GetGroupMembers(baseApiUrl, currentBookId, undefined, selectedManagedGroup.Id)
                .then((members) => {
                    setSelectedGroupMembers(members.data);
                    setSelectedGroupMembersLoading(false);
                });
        }
    };

    const handleGoNext = () => {
        if (externalGroups && managedGroups && tabSelectedIndex !== undefined && tabSelectedIndex < (externalGroups.length + managedGroups.length)) {
            handleGroupChange(tabSelectedIndex + 1);
        }
    }

    const handleExternalSave = (goNext: boolean) => {
        if (selectedExternalGroup && externalGroups && selectedExternalGroupIndex !== undefined && selectedExternalGroupIndex >= 0) {
            console.log(`Save settings for exernal group ${selectedExternalGroup.Id}`);
            setSavingGroup(true);
            const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

            GroupsApi.SaveExternalGroup(baseApiUrl, currentBookId, selectedExternalGroup, selectedGroupMembers)
                .then((res) => {
                    setExternalGroups([
                        ...externalGroups.slice(0, selectedExternalGroupIndex),
                        res.data,
                        ...externalGroups.slice(selectedExternalGroupIndex + 1)
                    ]);

                    setSelectedExternalGroup(res.data);
                    setSuccessMessage("Classe sauvegardée");
                }).catch(() => {
                    setErrorMessage("Erreur lors de la sauvegarde");
                }).finally(() => {
                    setSavingGroup(false);

                    if (goNext && tabSelectedIndex !== undefined) {
                        handleGroupChange(tabSelectedIndex + 1);
                    }
                });
        }
    }

    const handleManagedSave = () => {
        if (selectedManagedGroup && managedGroups && selectedManagedGroupIndex !== undefined) {
            console.log(`Save settings for managed group ${selectedManagedGroup.Id}`);
            setSavingGroup(true);
            const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

            GroupsApi.SaveManagedGroup(baseApiUrl, currentBookId, selectedManagedGroup, selectedGroupMembers)
                .then((res) => {
                    setSuccessMessage("Classe sauvegardée");
                    setManagedGroups([
                        ...managedGroups.slice(0, selectedManagedGroupIndex),
                        res.data,
                        ...managedGroups.slice(selectedManagedGroupIndex + 1)
                    ]);
                }).catch(() => {
                    setErrorMessage("Erreur lors de la sauvegarde");
                }).finally(() => {
                    setSavingGroup(false);
                });
        }
    }

    const handleChangeGroupName = (groupName: string) => {
        if (selectedManagedGroup && managedGroups && selectedManagedGroupIndex !== undefined) {
            console.log(`Change group name for managed group ${selectedManagedGroup.Id}`);
            const selGroup = selectedManagedGroup;
            selGroup.Name = groupName;

            //setSelectedManagedGroup(selGroup);
            setManagedGroups([
                ...managedGroups.slice(0, selectedManagedGroupIndex),
                selGroup,
                ...managedGroups.slice(selectedManagedGroupIndex + 1)
            ]);
        }
    }

    const handleManagedRemove = () => {
        if (selectedManagedGroup && managedGroups && selectedManagedGroupIndex !== undefined) {
            console.log(`delete managed group ${selectedManagedGroup.Id}`);
            const deletedGroupName = selectedManagedGroup.Name;
            setSavingGroup(true);

            const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

            GroupsApi.DeleteManagedGroup(baseApiUrl, currentBookId, selectedManagedGroup)
                .then((res) => {
                    setManagedGroups([
                        ...managedGroups.slice(0, selectedManagedGroupIndex),
                        ...managedGroups.slice(selectedManagedGroupIndex + 1)
                    ]);
                    setSelectedManagedGroup(undefined);
                    setSelectedManagedGroupIndex(undefined);
                    setTabSelectedIndex(undefined);
                    setSuccessMessage(`Classe ${deletedGroupName} supprimée`);
                }).catch(() => {
                    setErrorMessage("Erreur lors de la suppression de la classe");
                }).finally(() => {
                    setSavingGroup(false);
                });
        }
    }

    const handleManagedRemoveMyself = () => {
        if (selectedManagedGroup && managedGroups && selectedManagedGroupIndex !== undefined) {
            console.log(`remove myself from managed group ${selectedManagedGroup.Id}`);
            const removedGroupName = selectedManagedGroup.Name;
            setSavingGroup(true);

            const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

            GroupsApi.RemoveMyselfFromManagedGroup(baseApiUrl, currentBookId, selectedManagedGroup)
                .then((res) => {
                    setManagedGroups([
                        ...managedGroups.slice(0, selectedManagedGroupIndex),
                        ...managedGroups.slice(selectedManagedGroupIndex + 1)
                    ]);
                    setSelectedManagedGroup(undefined);
                    setSelectedManagedGroupIndex(undefined);
                    setTabSelectedIndex(undefined);
                    setSuccessMessage(`Vous avez quitté la classe ${removedGroupName}`);
                }).catch(() => {
                    setErrorMessage("Erreur lors de votre retrait de la classe");
                }).finally(() => {
                    setSavingGroup(false);
                });
        }
    }

    const handleAlertClose = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    }

    useEffect(() => {
        if (managedGroups && selectedManagedGroupIndex !== undefined) {
            setSelectedExternalGroupIndex(undefined);
            setSelectedExternalGroup(undefined);

            const newManagedGroup = selectedManagedGroupIndex !== undefined ? managedGroups[selectedManagedGroupIndex] : undefined;
            if (newManagedGroup?.Id !== selectedManagedGroup?.Id) {
                setSelectedGroupMembersLoading(true);
                setSelectedGroupMembers([]);
                setSelectedManagedGroup(newManagedGroup);
                setSelectedExternalGroup(undefined);

                if (newManagedGroup) {
                    const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

                    // Get groups
                    GroupsApi.GetGroupMembers(baseApiUrl, currentBookId, undefined, newManagedGroup.Id)
                        .then((members) => {
                            setSelectedGroupMembers(members.data);
                            setSelectedGroupMembersLoading(false);
                        });
                }
            }
        } else if (externalGroups && selectedExternalGroupIndex !== undefined) {
            setSelectedManagedGroupIndex(undefined);
            setSelectedManagedGroup(undefined);

            const newExternalGroup = selectedExternalGroupIndex !== undefined ? externalGroups[selectedExternalGroupIndex] : undefined;
            if (newExternalGroup !== selectedExternalGroup) {
                setSelectedGroupMembersLoading(true);
                setSelectedGroupMembers([]);
                setSelectedExternalGroup(newExternalGroup);
                setSelectedManagedGroup(undefined);

                if (newExternalGroup) {
                    const baseApiUrl = _spPageContextInfo ? _spPageContextInfo.webServerRelativeUrl : window.document.location.origin;

                    GroupsApi.GetGroupMembers(baseApiUrl, currentBookId, newExternalGroup.Id, undefined)
                        .then((members) => {
                            setSelectedGroupMembers(members.data);
                            setSelectedGroupMembersLoading(false);
                        });
                }
            }
        }
    }, [managedGroups, selectedManagedGroupIndex, externalGroups, selectedExternalGroupIndex]);

    function a11yProps(index: number) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    var tabs = [...(externalGroups ? externalGroups.map((g, idx) => (<Tab
        key={`external-${g.Id}`}
        selected={
            (selectedExternalGroup?.Id === g.Id)
        }
        classes={{
            root: classes.tab,
            selected: classes.tabSelected
        }}
        wrapped
        label={g.Name}
        {...a11yProps(idx)} />)) : []),
    ...(managedGroups ? managedGroups.map((g, idx) => (<Tab
        key={`managed-${g.Id}`}
        selected={
            (selectedManagedGroup?.Id === g.Id)
        }
        classes={{
            root: classes.tab,
            selected: classes.tabSelected
        }}
        wrapped
        label={`${g.Name || 'Nouvelle classe'}${g.IsNew ? ' *' : ''}`}
        {...a11yProps(idx + (externalGroups ? externalGroups.length : 0))} />)) : []),
    (<Tab
        key={'new-class'}
        selected={false}
        classes={{
            root: classes.tabNew,
            selected: classes.tabSelected
        }}
        wrapped
        label={<div><AddIcon style={{ verticalAlign: 'bottom' }} fontSize="small" /> Créer nouvelle classe </div>}
        {...a11yProps((externalGroups ? externalGroups.length : 0) + (managedGroups ? managedGroups.length : 0) + 1)} />)
    ];

    return (<div className={classes.root}>
        {externalGroups && managedGroups && (<>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabSelectedIndex !== undefined ? tabSelectedIndex : false}
                    onChange={(ev, newValue) => handleGroupChange(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={"scrollable"}
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {tabs}
                </Tabs>
            </AppBar>
            {selectedExternalGroup && (<>
                {
                    externalGroups.map((g, idx) => (
                        <ExternalGroupTabPanel
                            key={`tab-external-${g.Id}`}
                            id={g.Id}
                            firstTime={firstTime && idx < tabs.length - 2}
                            bookId={currentBookId}
                            canSave={!savingGroup}
                            value={selectedExternalGroup}
                            groupMembers={selectedGroupMembers}
                            setGroupMembers={setSelectedGroupMembers}
                            groupMembersLoading={selectedGroupMembersLoading}
                            setGroupIsManaged={handleGroupIsManaged}
                            setGroupMemberIsIgnored={handleGroupMemberIsIgnored}
                            goNext={handleGoNext}
                            cancel={handleExternalCancel}
                            save={handleExternalSave} />
                    ))
                }
            </>)}
            {selectedManagedGroup && (<>
                {
                    managedGroups.map((g, idx) => (
                        <ManagedGroupTabPanel
                            key={`tab-managed-${g.Id}`}
                            id={g.Id}
                            firstTime={firstTime && (idx + externalGroups.length) < tabs.length - 2}
                            bookId={currentBookId}
                            canSave={!savingGroup}
                            value={selectedManagedGroup}
                            groupMembers={selectedGroupMembers}
                            setGroupMembers={setSelectedGroupMembers}
                            groupMembersLoading={selectedGroupMembersLoading}
                            changeGroupName={handleChangeGroupName}
                            save={handleManagedSave}
                            goNext={handleGoNext}
                            cancel={handleManagedCancel}
                            remove={handleManagedRemove}
                            removeMyself={handleManagedRemoveMyself}
                        />
                    ))
                }
            </>)}
            {firstTime && ((externalGroups.filter((g) => g.IsManaged).length === 0) || (managedGroups.filter((g) => g.IsNew).length > 0 && managedGroups.filter((g) => !g.IsNew).length === 0)) && (
                <div style={{ width: '100%', marginTop: 20 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => window.top.document.location.href = _spPageContextInfo.webServerRelativeUrl}>J'accède au i-Manuel sans créer de classe</Button>
                </div>
            )}
        </>)}
        {loadingGroups && (
            <div style={{ width: '100%', marginTop: 50, marginBottom: 50, textAlign: 'center' }}>
                <CircularProgress color="secondary" size={60} />
            </div>)}
        <Snackbar
            open={(successMessage !== undefined || errorMessage !== undefined)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={handleAlertClose}>
            <Alert onClose={handleAlertClose} severity={errorMessage ? "error" : "success"}>
                {successMessage ? successMessage : errorMessage}
            </Alert>
        </Snackbar>

    </div>);
}

export default GroupsDashboard;